import { bool, string } from "prop-types"
import Skeleton from "react-loading-skeleton"
import TextTruncate from "react-text-truncate"
import LinkHandler from "components/UI/LinkHandler"
import RemoteImage from "../../RemoteImage"
import React, { useRef, useEffect, useState } from "react"

const CustomCard = ({ className, image, title, href, subtitle, state }) => {
  const titleRef = useRef(null)
  const subTitleRef = useRef(null)
  var isShow = useState(state)

  useEffect(() => {
    titleRef.current?.update()
    subTitleRef.current?.update()
  }, [isShow])

  return (
    <div className={className}>
      <LinkHandler href={href} className="flex flex-col space-y-2 lg:space-y-4">
        <RemoteImage
          className="bg-base-indented-light aspect-ratio-2/3 leading-zero"
          src={image}
          alt={title}
          scale={2}
        />

        <div className="flex flex-col space-y-2">
          <h2 className="fs-small-regular lg:fs-base-interface-bold line-clamp-1 ">
            {title ? (
              <TextTruncate text={title} line={1} ref={titleRef} />
            ) : (
              <Skeleton count={1} className="react-loading-skeleton" />
            )}
          </h2>

          <h3 className="hidden lg:block fs-small-regular text-element-medium-emphasis-light">
            {subtitle ? (
              <TextTruncate text={subtitle} line={2} ref={subTitleRef} />
            ) : (
              <Skeleton count={2} className="react-loading-skeleton" />
            )}
          </h3>
        </div>
      </LinkHandler>
    </div>
  )
}

CustomCard.propTypes = {
  className: string,
  image: string,
  title: string,
  href: string,
  subtitle: string,
  state: bool,
}

export default CustomCard
