import classNames from "classnames"
import { func, node, string } from "prop-types"
import ListButtonContent from "./Content"

const ListButton = ({ onClick, href, target, rel, className, icon, label }) => {
  const classes = classNames(
    "transition-colors duration-100 ease-in",
    className,
  )
  if (href)
    return (
      <a
        href={href}
        onClick={onClick}
        target={target}
        rel={rel}
        className={classes}>
        <ListButtonContent icon={icon} label={label} />
      </a>
    )

  if (onClick)
    return (
      <button onClick={onClick} className={classes}>
        <ListButtonContent icon={icon} label={label} />
      </button>
    )

  return (
    <div className={classes}>
      <ListButtonContent icon={icon} label={label} />
    </div>
  )
}

ListButton.propTypes = {
  onClick: func,
  href: string,
  target: string,
  rel: string,
  className: string,
  icon: node,
  label: string,
}

export default ListButton
