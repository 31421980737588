import { array, string } from "prop-types"
import CardFeaturedVideo from "components/UI/Card/FeaturedVideo"
import CardFeaturedVideoMore from "components/UI/Card/FeaturedVideo/More"
import SectionHeader from "./Header"
import SectionListGrid from "./List/Grid"
import SectionListScrollableHorizontal from "./List/ScrollableHorizontal"

const SectionFeaturedVideos = ({
  className,
  title,
  subtitle,
  pageUrl,
  items,
}) => {
  const showAllLabel = title && `Show all ${title.toLowerCase()}`

  return (
    <section className={className}>
      <div className="flex flex-col space-y-section">
        <SectionHeader
          className="box-x-wrapper"
          title={title}
          subtitle={subtitle}
          linkUrl={pageUrl}
          linkLabel="Show all"
        />

        <SectionListScrollableHorizontal
          className="md:hidden"
          items={items}
          pageUrl={pageUrl}
          rowsCount={1}
          renderItem={(item) => (
            <CardFeaturedVideo
              className="flex-shrink-0 w-38 sm:w-47"
              href={item?.web_url}
              image={item?.image_path}
              title={item?.title}
              subtitle={item?.sub_title}
              showOriginalBadge={item?.original_badge}
              state={item?.state}
            />
          )}
          itemMore={
            <CardFeaturedVideoMore
              className="flex-shrink-0 w-38 sm:w-47"
              href={pageUrl}
              text={showAllLabel}
            />
          }
        />

        <SectionListGrid
          className="hidden md:block"
          items={items}
          pageUrl={pageUrl}
          maxItemsPerRow={4}
          initialVisibleRows={1}
          showAllLabel={showAllLabel}
          renderItem={(item) => (
            <CardFeaturedVideo
              href={item?.web_url}
              image={item?.image_path}
              title={item?.title}
              subtitle={item?.sub_title}
              showOriginalBadge={item?.original_badge}
              state={item?.state}
            />
          )}
          itemMore={
            <CardFeaturedVideoMore href={pageUrl} text={showAllLabel} />
          }
        />
      </div>
    </section>
  )
}

SectionFeaturedVideos.propTypes = {
  className: string,
  title: string,
  subtitle: string,
  pageUrl: string,
  items: array,
}

export default SectionFeaturedVideos
