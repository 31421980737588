import { string } from "prop-types"
import LinkHandler from "components/UI/LinkHandler"
import SectionHeaderCell from "components/UI/SectionHeaderCell"

const SectionHeader = ({
  className,
  title,
  subtitle,
  linkUrl,
  linkLabel,
}) => (
  <div className={className}>
    <div className="flex items-end justify-between space-x-4">
      <SectionHeaderCell
        titleLabel={title}
        subLabel={subtitle}
        className="flex-1"
      />

      {linkUrl && (
        <LinkHandler
          href={linkUrl}
          className="fs-small-link text-element-medium-emphasis-light hover:text-element-high-emphasis-light md:hidden">
          {linkLabel}
        </LinkHandler>
      )}
    </div>
  </div>
)

SectionHeader.propTypes = {
  className: string,
  title: string,
  subtitle: string,
  linkUrl: string,
  linkLabel: string,
}

export default SectionHeader
