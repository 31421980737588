import PropTypes from "prop-types"

const BannerTitles = ({ title, subtitle, className }) => {
  return (
    <div className={className}>
      <p className="fs-x-large-bold leading-semi-interface lg:fs-4x-large-bold lg:leading-paragraph">
        {title}
      </p>
      <p className="fs-x-large-regular leading-semi-interface lg:fs-4x-large-regular lg:leading-paragraph">
        {subtitle}
      </p>
    </div>
  )
}

BannerTitles.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default BannerTitles
