import { NobodySurfIcon } from "assets/icons"
import { string } from "prop-types"

const BadgeOriginal = ({ className }) => (
  <div className={className}>
    <div className="flex items-center space-x-1">
      <NobodySurfIcon className="w-5 h-5 lg:w-7 lg:h-7" />

      <div className="font-medium uppercase text-x-small leading-interface tracking-x-wide lg:text-small">
        Originals
      </div>
    </div>
  </div>
)

BadgeOriginal.propTypes = { className: string }

export default BadgeOriginal
