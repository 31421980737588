import { string } from "prop-types"
import CardMore from "components/UI/Card/More"

const CardPlaylistMore = ({ className, text, href }) => (
  <CardMore
    className={className}
    text={text}
    href={href}
    aspectRatio="square"
  />
)

CardPlaylistMore.propTypes = {
  className: string,
  text: string.isRequired,
  href: string.isRequired,
}

export default CardPlaylistMore
