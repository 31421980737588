const splitInChunks = (array, count) => {
  if (!array || !count) return []
  const chunks = []

  for (let i = 0; i < count; i++) {
    const chunk = array.filter((item, j) => j % count === i)
    chunks.push(chunk)
  }

  return chunks
}

export default splitInChunks
