import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Skeleton from "react-loading-skeleton"

const SectionHeaderCell = (props) => {
  return (
    <div className={props.className}>
      <div className="flex flex-col space-y-4 break-words">
        <div className="flex flex-col justify-end space-y-1">
          <h1 className="fs-x-large-bold lg:fs-2x-large-bold text-element-high-emphasis-light">
            {props.titleLabel || <Skeleton width={200} />}
          </h1>
          <h2
            className={classNames(
              props.subLabelColor,
              "fs-small-regular whitespace-pre-line",
            )}>
            {props.subLabel || <Skeleton width={150} />}
          </h2>
        </div>
        {props.children}
      </div>
    </div>
  )
}

SectionHeaderCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  titleLabel: PropTypes.string.isRequired,
  subLabel: PropTypes.string,
  subLabelColor: PropTypes.string,
}

SectionHeaderCell.defaultProps = {
  subLabelColor: "text-element-medium-emphasis-light",
}

export default SectionHeaderCell
