import { array, string } from "prop-types"
import CardVideo from "components/UI/Card/Video"
import CardVideoMore from "components/UI/Card/Video/More"
import formatVideoDuration from "lib/format/formatVideoDuration"
import formatVideoMetadataDescription from "lib/format/formatVideoMetadataDescription"
import SectionHeader from "./Header"
import SectionListGrid from "./List/Grid"
import SectionListScrollableHorizontal from "./List/ScrollableHorizontal"

const SectionVideos = ({
  className,
  title,
  subtitle,
  pageUrl,
  items,
  mobileRowsCount,
}) => {
  const showAllLabel = title && `Show all ${title.toLowerCase()}`

  return (
    <section className={className}>
      <div className="flex flex-col space-y-section">
        <SectionHeader
          className="box-x-wrapper"
          title={title}
          subtitle={subtitle}
          linkUrl={pageUrl}
          linkLabel="Show all"
        />

        <SectionListScrollableHorizontal
          className="md:hidden"
          items={items}
          pageUrl={pageUrl}
          rowsCount={
            (mobileRowsCount == "single" && 1) ||
            (mobileRowsCount == "double" && 2)
          }
          renderItem={(item, index) => (
            <CardVideo
              className="flex-shrink-0 w-38 sm:w-47"
              position={index}
              id={item?.movie_id}
              href={item?.web_url}
              thumbnail={item?.image_path}
              title={item?.movie_title}
              duration={formatVideoDuration(item)}
              description={item?.description}
              metadataDescription={formatVideoMetadataDescription(item)}
              state={item?.state}
              key={item?.movie_id || index}
            />
          )}
          itemMore={
            <CardVideoMore
              className="flex-shrink-0 w-38 sm:w-47"
              text={showAllLabel}
              href={pageUrl}
            />
          }
        />

        <SectionListGrid
          className="hidden md:block"
          items={items}
          pageUrl={pageUrl}
          maxItemsPerRow={4}
          initialVisibleRows={2}
          showAllLabel={showAllLabel}
          renderItem={(item, index) => (
            <CardVideo
              position={index}
              id={item?.movie_id}
              href={item?.web_url}
              thumbnail={item?.image_path}
              title={item?.movie_title}
              duration={formatVideoDuration(item)}
              description={item?.description}
              metadataDescription={formatVideoMetadataDescription(item)}
              state={item?.state}
              key={item?.movie_id || index}
            />
          )}
          itemMore={<CardVideoMore text={showAllLabel} href={pageUrl} />}
        />
      </div>
    </section>
  )
}

SectionVideos.propTypes = {
  className: string,
  title: string.isRequired,
  subtitle: string.isRequired,
  pageUrl: string.isRequired,
  items: array,
  mobileRowsCount: string,
}

export default SectionVideos
