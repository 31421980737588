import { ArrowRightAltIcon } from "assets/icons"
import LinkHandler from "components/UI/LinkHandler"
import ListButton from "components/UI/ListButton"
import { string } from "prop-types"

const CardKeywordMore = ({ href }) => (
  <LinkHandler href={href}>
    <ListButton
      className="text-element-medium-emphasis-light bg-base-elevated-light hover:bg-base-push-light hover:text-element-high-emphasis-light"
      icon={<ArrowRightAltIcon alt="Show all keywords" />}
      label="Show all keywords"
    />
  </LinkHandler>
)

CardKeywordMore.propTypes = { href: string }

export default CardKeywordMore
