import { array, string } from "prop-types"
import CardKeyword from "components/UI/Card/Keyword"
import CardKeywordMore from "components/UI/Card/Keyword/More"
import SectionHeader from "./Header"
import SectionListGrid from "./List/Grid"
import SectionListScrollableHorizontal from "./List/ScrollableHorizontal"

const SectionKeywords = ({ className, title, subtitle, pageUrl, items }) => (
  <section className={className}>
    <div className="flex flex-col space-y-section">
      <SectionHeader
        className="box-x-wrapper"
        title={title}
        subtitle={subtitle}
        linkUrl={pageUrl}
        linkLabel="Show all keywords"
      />

      <SectionListScrollableHorizontal
        className="md:hidden"
        items={items}
        pageUrl={pageUrl}
        renderItem={({ name, icon, tag }) => (
          <CardKeyword name={name} icon={icon} tag={tag} />
        )}
      />

      <SectionListGrid
        className="hidden md:block"
        items={items}
        pageUrl={pageUrl}
        maxItemsPerRow={4}
        showAllLabel="Show all keywords"
        gapSize="small"
        renderItem={({ name, icon, tag }) => (
          <CardKeyword name={name} icon={icon} tag={tag} />
        )}
        itemMore={<CardKeywordMore href={pageUrl} />}
      />
    </div>
  </section>
)

SectionKeywords.propTypes = {
  className: string,
  title: string,
  subtitle: string,
  pageUrl: string,
  items: array,
}

export default SectionKeywords
