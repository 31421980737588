import { string } from "prop-types"
import Divider from "components/UI/Divider"

const SectionDivider = ({ className }) => (
  <div className={className}>
    <Divider />
  </div>
)

SectionDivider.propTypes = {
  className: string,
}

export default SectionDivider
