import React, { useRef, useEffect, useState } from "react"
import classNames from "classnames"
import { string, bool } from "prop-types"
import Skeleton from "react-loading-skeleton"
import TextTruncate from "react-text-truncate"
import LinkHandler from "components/UI/LinkHandler"
import RemoteImage from "../../RemoteImage"
import { event } from "lib/gtag"

const CardFeaturedPost = ({
  href,
  className,
  image,
  title,
  subtitle,
  subtitleAsButton,
  state,
}) => {
  const truncateRef = useRef(null)
  var isShow = useState(state)

  useEffect(() => {
    truncateRef?.current?.update()
  }, [isShow])

  const handleClick = (label) => {
    event({ action: "FeaturedPost", category: "Click", label: label })
  }

  return (
    <LinkHandler
      href={href}
      onClick={() => handleClick(title)}
      className={className}>
      <div className="flex items-center space-x-4 transition-opacity hover:opacity-75">
        <div className="flex-shrink-0 w-14 h-14">
          <RemoteImage
            className="align-top bg-element-divider-light aspect-ratio-square leading-zero"
            src={image}
            alt={title}
          />
        </div>

        <div className="flex flex-col flex-1 space-y-1">
          <h2 className="fs-base-interface-bold text-element-high-emphasis-light line-clamp-2">
            {title ? (
              <TextTruncate text={title} line={2} ref={truncateRef} />
            ) : (
              <Skeleton count={2} className="react-loading-skeleton" />
            )}
          </h2>

          <div
            className={classNames(
              !subtitleAsButton ? "fs-small-regular" : "fs-small-link",
              "text-element-medium-emphasis-light",
            )}>
            {subtitle ? (
              <span>{subtitle}</span>
            ) : (
              <Skeleton count={1} className="react-loading-skeleton" />
            )}
          </div>
        </div>
      </div>
    </LinkHandler>
  )
}

CardFeaturedPost.defaultProps = {
  subtitleAsButton: true,
}

CardFeaturedPost.propTypes = {
  href: string.isRequired,
  className: string,
  image: string,
  title: string,
  subtitle: string,
  subtitleAsButton: bool,
  state: bool,
}

export default CardFeaturedPost
