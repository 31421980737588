import { string } from "prop-types"
import { ArrowRightAltIcon } from "assets/icons"
import LinkHandler from "components/UI/LinkHandler"

const CardMore = ({ className, text, href, aspectRatio }) => (
  <div className={className}>
    <div className="relative">
      <div className={`aspect-ratio-${aspectRatio}`}></div>

      <LinkHandler
        href={href}
        className="absolute inset-0 flex flex-col items-center justify-center w-full h-full p-4 space-y-1 transition-colors bg-base-indented-light text-element-medium-emphasis-light fs-small-regular hover:bg-base-push-light hover:text-element-high-emphasis-light">
        <ArrowRightAltIcon className="w-4 h-4" />
        <div className="text-center">{text}</div>
      </LinkHandler>
    </div>
  </div>
)

CardMore.propTypes = {
  className: string,
  text: string.isRequired,
  href: string.isRequired,
  aspectRatio: string,
}

CardMore.defaultProps = {
  aspectRatio: "16/9",
}

export default CardMore
