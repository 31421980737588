import { array, string } from "prop-types"
import CardPlaylist from "components/UI/Card/Playlist"
import CardPlaylistMore from "components/UI/Card/Playlist/More"
import SectionHeader from "./Header"
import SectionListScrollableHorizontal from "./List/ScrollableHorizontal"
import SectionListGrid from "./List/Grid"

const SectionPlaylists = ({
  className,
  title,
  subtitle,
  pageUrl,
  items,
  mobileRowsCount,
}) => {
  const showAllLabel = title && `Show all ${title.toLowerCase()}`
  return (
    <section className={className}>
      <div className="flex flex-col space-y-section">
        <SectionHeader
          className="box-x-wrapper"
          title={title}
          subtitle={subtitle}
          linkUrl={pageUrl}
          linkLabel="Show all"
        />

        <SectionListScrollableHorizontal
          className="md:hidden"
          items={items}
          pageUrl={pageUrl}
          rowsCount={
            (mobileRowsCount === "single" && 1) ||
            (mobileRowsCount === "double" && 2)
          }
          renderItem={({ title, sub_title, cover, url, state }) => (
            <CardPlaylist
              className="flex-shrink-0 w-24 sm:w-30"
              image={cover}
              title={title}
              subtitle={sub_title}
              href={url}
              state={state}
            />
          )}
          itemMore={
            <CardPlaylistMore
              className="flex-shrink-0 w-24 sm:w-30"
              text={showAllLabel}
              href={pageUrl}
            />
          }
        />

        <SectionListGrid
          className="hidden md:block"
          items={items}
          pageUrl={pageUrl}
          maxItemsPerRow={5}
          initialVisibleRows={2}
          showAllLabel={showAllLabel}
          renderItem={({ title, sub_title, cover, url, state }) => (
            <CardPlaylist
              image={cover}
              title={title}
              subtitle={sub_title}
              href={url}
              state={state}
            />
          )}
          itemMore={<CardPlaylistMore text={showAllLabel} href={pageUrl} />}
        />
      </div>
    </section>
  )
}

SectionPlaylists.propTypes = {
  className: string,
  title: string,
  subtitle: string,
  pageUrl: string,
  items: array,
  mobileRowsCount: string,
}

export default SectionPlaylists
