import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { isMobile } from "react-device-detect"
import urlcat from "urlcat"

const BannerVideo = ({
  videoSources,
  className,
  onError,
  onCanPlayThrough,
}) => {
  const poster = urlcat(videoSources.poster, {
    f: "auto",
    d: isMobile ? 320 : 640,
  })
  // レンダー後にVideoの呼び出しをする
  const [loadVideo, setLoadVideo] = useState(false)
  useEffect(() => {
    setLoadVideo(true)
  }, [loadVideo])

  return (
    <div className={classNames("absolute inset-0", className)}>
      {loadVideo && (
        <video
          preload="none"
          autoPlay
          loop
          muted
          playsInline
          webkit-playsinline="true"
          onError={onError}
          onCanPlayThrough={onCanPlayThrough}
          poster={poster}
          className="z-0 object-cover w-full h-full">
          {isMobile && videoSources.mobile && videoSources.mobile !== "" ? (
            <source src={videoSources.mobile} type="video/mp4" />
          ) : (
            <>
              {videoSources.webm != "" && (
                <source src={videoSources.webm} type="video/webm" />
              )}
              {videoSources.mp4 != "" && (
                <source src={videoSources.mp4} type="video/mp4" />
              )}
            </>
          )}
        </video>
      )}
    </div>
  )
}

BannerVideo.propTypes = {
  videoRef: PropTypes.func,
  className: PropTypes.string,
  onError: PropTypes.func,
  videoSources: PropTypes.shape({
    poster: PropTypes.string.isRequired,
    mp4: PropTypes.string.isRequired,
    webm: PropTypes.string.isRequired,
    mobile: PropTypes.string,
  }),
  onCanPlayThrough: PropTypes.func,
}

export default BannerVideo
