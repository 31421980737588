import { string } from "prop-types"
import CardMore from "components/UI/Card/More"

const CardVideoMore = ({ className, href, text }) => (
  <CardMore className={className} text={text} aspectRatio="16/9" href={href} />
)

CardVideoMore.propTypes = {
  className: string,
  href: string,
  text: string,
}

CardVideoMore.defaultProps = {
  text: "Show all videos",
}

export default CardVideoMore
