import { string } from "prop-types"
import SearchTagIcons from "components/UI/IllustrativeIcon"
import ListButton from "components/UI/ListButton"

const CardKeyword = ({ className, name, icon, tag }) => (
  <div className={className}>
    <a href={`/search?tags[]=` + tag}>
      <ListButton
        className={
          icon || name
            ? "bg-base-elevated-light hover:bg-base-push-light"
            : undefined
        }
        icon={<SearchTagIcons icon={icon} alt={name} />}
        label={name}
      />
    </a>
  </div>
)

CardKeyword.propTypes = {
  className: string,
  name: string,
  icon: string,
  tag: string,
}

export default CardKeyword
