import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import urlcat from "urlcat"
import Image from "next/image"

const PosterLoader = ({ src }) => {
  return urlcat(src, { f: "auto" })
}

const BannerPoster = ({ alt, videoSources, className, onAfterLoad }) => {
  return (
    <div className={classNames("absolute inset-0", className)}>
      <Image
        src={videoSources.poster}
        loader={PosterLoader}
        layout="fill"
        alt={alt}
        onLoad={onAfterLoad}
        className="z-0 object-cover w-full h-full"
      />
    </div>
  )
}

BannerPoster.propTypes = {
  className: PropTypes.string,
  onAfterLoad: PropTypes.func,
  alt: PropTypes.string.isRequired,
  videoSources: PropTypes.shape({
    poster: PropTypes.string.isRequired,
  }),
}

export default BannerPoster
