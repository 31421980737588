import { string, array } from "prop-types"
import CardFeaturedPost from "components/UI/Card/FeaturedPost"

const SectionFeaturedPosts = ({ className, items }) => {
  if (items && items.length > 0) {
    return (
      <section className={className}>
        <div className="my-4 bg-base-indented-light md:bg-transparent md:my-6 lg:16">
          <div className="flex flex-col py-6 space-y-6 box-x-wrapper md:bg-base-indented-light md:px-6">
            {items?.map(({ message, link_text, link_url, image_url, id }) => (
              <CardFeaturedPost
                image={image_url}
                title={message}
                subtitle={link_text}
                subtitleAsButton={true}
                href={link_url}
                key={id}
              />
            ))}
          </div>
        </div>
      </section>
    )
  } else {
    return <></>
  }
}

SectionFeaturedPosts.propTypes = {
  className: string,
  items: array,
}

export default SectionFeaturedPosts
