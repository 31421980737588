import React, { useRef, useEffect, useState } from "react"
import { bool, string } from "prop-types"
import BadgeOriginal from "../../Badge/Original"
import LinkHandler from "components/UI/LinkHandler"
import RemoteImage from "../../RemoteImage"
import TextTruncate from "react-text-truncate"
import classNames from "classnames"

const CardFeaturedVideo = ({
  className,
  href,
  image,
  title,
  subtitle,
  showOriginalBadge,
  state,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const titleRef = useRef(null)
  const subTitleRef = useRef(null)
  var isShow = useState(state)

  useEffect(() => {
    titleRef.current?.update()
    subTitleRef.current?.update()
  }, [isShow])

  return (
    <div className={className}>
      <RemoteImage
        className="aspect-ratio-9/16 bg-base-indented-light leading-zero"
        src={image}
        scale={2}
        alt={title}
        afterLoad={() => setImageLoaded(true)}>
        <LinkHandler
          href={href}
          className="absolute inset-0 z-20 flex flex-col-reverse justify-between w-full h-full text-element-extra-emphasis-dark ">
          {(title || subtitle) && (
            <div
              className={classNames(
                "flex flex-col px-3 py-4 bg-gradient-to-t from-base-image-push-dark to-transparent space-y-2 lg:px-5 lg:py-6 transition-opacity duration-250",
                imageLoaded ? "opacity-100" : "opacity-0",
              )}>
              {title && (
                <div className="fs-base-interface-bold lg:fs-2x-large-bold line-clamp-3">
                  <TextTruncate text={title} line={3} ref={titleRef} />
                </div>
              )}

              {subtitle && (
                <div className="font-bold uppercase text-x-small leading-interface tracking-x-wide lg:text-small lg:font-semibold line-clamp-1">
                  <TextTruncate text={subtitle} line={1} ref={subTitleRef} />
                </div>
              )}
            </div>
          )}

          {showOriginalBadge && (
            <div
              className={classNames(
                "p-3 bg-gradient-to-b from-base-image-push-dark to-transparent lg:p-5 transition-opacity duration-250",
                imageLoaded ? "opacity-100" : "opacity-0",
              )}>
              <BadgeOriginal />
            </div>
          )}
        </LinkHandler>
      </RemoteImage>
    </div>
  )
}

CardFeaturedVideo.propTypes = {
  className: string,
  href: string,
  image: string,
  title: string.isRequired,
  subtitle: string,
  showOriginalBadge: bool,
  state: bool,
}

CardFeaturedVideo.defaultProps = {
  showOriginalBadge: false,
}

export default CardFeaturedVideo
