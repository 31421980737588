import { string } from "prop-types"
import CardMore from "components/UI/Card/More"

const CardFeaturedVideoMore = ({ className, text, href }) => (
  <CardMore className={className} text={text} href={href} aspectRatio="9/16" />
)

CardFeaturedVideoMore.propTypes = {
  className: string,
  text: string.isRequired,
  href: string.isRequired,
}
export default CardFeaturedVideoMore
