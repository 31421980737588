import { string } from "prop-types"

import {
  SurfboardIcon,
  FinLessIcon,
  FinSingleIcon,
  FinTwinIcon,
  FinTriIcon,
  FinBonzerIcon,
  WaveSmallIcon,
  WaveBigIcon,
  WaveChestHeadIcon,
  WaveHugeIcon,
  WaveOverheadIcon,
  StanceRegularIcon,
  StanceGoofyIcon,
  GenderMaleIcon,
  GenderFemaleIcon,
  SurferAltIcon,
  SurferIcon,
  CircleIcon,
  RuntimeIcon,
} from "assets/icons"

const SearchTagIcons = ({ icon, className, alt }) => {
  if (icon) {
    if (icon.match(/surfboard/i)) {
      return <SurfboardIcon className={className} alt={alt} />
    } else if (icon.match(/fin-less/i)) {
      return <FinLessIcon className={className} alt={alt} />
    } else if (icon.match(/fin-single/i)) {
      return <FinSingleIcon className={className} alt={alt} />
    } else if (icon.match(/fin-twin/i)) {
      return <FinTwinIcon className={className} alt={alt} />
    } else if (icon.match(/fin-tri/i)) {
      return <FinTriIcon className={className} alt={alt} />
    } else if (icon.match(/fin-bonzer/i)) {
      return <FinBonzerIcon className={className} alt={alt} />
    } else if (icon.match(/wave-small/i)) {
      return <WaveSmallIcon className={className} alt={alt} />
    } else if (icon.match(/wave-chest-head/i)) {
      return <WaveChestHeadIcon className={className} alt={alt} />
    } else if (icon.match(/wave-overhead/i)) {
      return <WaveOverheadIcon className={className} alt={alt} />
    } else if (icon.match(/wave-big/i)) {
      return <WaveBigIcon className={className} alt={alt} />
    } else if (icon.match(/wave-huge/i)) {
      return <WaveHugeIcon className={className} alt={alt} />
    } else if (icon.match(/stance-regular/i)) {
      return <StanceRegularIcon className={className} alt={alt} />
    } else if (icon.match(/stance-goofy/i)) {
      return <StanceGoofyIcon className={className} alt={alt} />
    } else if (icon.match(/gender-male/i)) {
      return <GenderMaleIcon className={className} alt={alt} />
    } else if (icon.match(/gender-female/i)) {
      return <GenderFemaleIcon className={className} alt={alt} />
    } else if (icon.match(/surfer-alt/i)) {
      return <SurferAltIcon className={className} alt={alt} />
    } else if (icon.match(/surfer/i)) {
      return <SurferIcon className={className} alt={alt} />
    } else if (icon.match(/runtime/i)) {
      return <RuntimeIcon className={className} alt={alt} />
    }
  }
  return <CircleIcon className={className} alt={alt} />
}

SearchTagIcons.propTypes = {
  className: string,
  icon: string.isRequired,
  alt: string.isRequired,
}

export default SearchTagIcons
