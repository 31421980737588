import { node, string } from "prop-types"
import Skeleton from "react-loading-skeleton"

const ListButtonContent = ({ icon, label }) => {
  if (!icon || !label)
    return (
      <div className="w-full h-12 min-w-32">
        <Skeleton
          width="100%"
          height="100%"
          className="react-loading-skeleton"
        />
      </div>
    )

  return (
    <div className="flex items-center w-full h-12 p-3 space-x-2 fs-base-interface-bold">
      {icon && <div className="w-6 h-6">{icon}</div>}
      {label && <span className="whitespace-nowrap">{label}</span>}
    </div>
  )
}

ListButtonContent.propTypes = {
  icon: node,
  label: string,
}

export default ListButtonContent
