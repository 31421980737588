import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import BannerTitles from "./Titles"
import Link from "next/link"
import BannerDownload from "./Download"
import BannerPoster from "./Poster"
import BannerVideo from "./Video"

const Banner = ({
  className,
  title,
  subtitle,
  videoSources,
  showDownload,
  children,
  link,
  target,
  onClick,
}) => {
  const transitionStyles = "transition duration-500"
  const [opacity, setOpacity] = useState("opacity-0")
  const [textColor, setTextColor] = useState(
    "text-element-extra-emphasis-light",
  )

  return (
    <BannerLink link={link} target={target} onClick={onClick}>
      <div
        className={classNames(
          className,
          "flex relative bg-element-divider-light",
        )}>
        <div
          className={classNames("absolute inset-0", transitionStyles, opacity)}
          style={{ background: "rgba(0, 0, 0, .2)" }}
        />

        {videoSources && videoSources.mp4 !== "" ? (
          <BannerVideo
            className={classNames(transitionStyles, opacity)}
            onError={() => setOpacity("opacity-100")}
            onCanPlayThrough={() => {
              setTextColor("text-element-extra-emphasis-dark")
              setOpacity("opacity-100")
            }}
            videoSources={videoSources}
          />
        ) : videoSources.poster ? (
          <BannerPoster
            alt={title}
            videoSources={videoSources}
            className={classNames(transitionStyles, opacity)}
            onAfterLoad={() => {
              setTextColor("text-element-extra-emphasis-dark")
              setOpacity("opacity-100")
            }}
          />
        ) : null}
        <div className="z-10 flex-1 py-12 md:py-20 lg:py-32 max:py-48 box-x-wrapper">
          {(title || subtitle) && (
            <BannerTitles
              title={title}
              subtitle={subtitle}
              className={classNames(transitionStyles, textColor)}
            />
          )}
          {showDownload && <BannerDownload className="mt-6 md:mt-10" />}
          {children}
        </div>
      </div>
    </BannerLink>
  )
}

const BannerLink = ({ link, target, onClick, children }) => {
  if (link)
    return (
      <Link href={link} prefetch={false} passHref>
        <a target={target} onClick={onClick}>
          {children}
        </a>
      </Link>
    )
  return children
}

Banner.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  videoSources: PropTypes.object,
  showDownload: PropTypes.bool,
  link: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

Banner.defaultProps = {
  target: "_self",
}

export default Banner
