import { array, string } from "prop-types"
import CustomCard from "components/UI/Card/CustomCard"
import SectionHeader from "./Header"
import SectionListScrollableHorizontal from "./List/ScrollableHorizontal"
import SectionListGrid from "./List/Grid"

const SectionCards = ({
  className,
  title,
  subtitle,
  items,
  mobileRowsCount,
}) => {
  return (
    <section className={className}>
      <div className="flex flex-col space-y-section">
        <SectionHeader
          className="box-x-wrapper"
          title={title}
          subtitle={subtitle}
          linkLabel="Show all"
        />

        <SectionListScrollableHorizontal
          className="md:hidden"
          items={items}
          renderItem={({ title, sub_title, link_url, image_url, state }) => (
            <CustomCard
              className="flex-shrink-0 w-24 sm:w-30"
              image={image_url}
              title={title}
              subtitle={sub_title}
              href={link_url}
              state={state}
            />
          )}
        />

        <SectionListGrid
          className="hidden md:block"
          items={items}
          maxItemsPerRow={5}
          initialVisibleRows={2}
          renderItem={({ title, sub_title, link_url, image_url, state }) => (
            <CustomCard
              image={image_url}
              title={title}
              subtitle={sub_title}
              href={link_url}
              state={state}
            />
          )}
        />
      </div>
    </section>
  )
}

SectionCards.propTypes = {
  className: string,
  title: string,
  subtitle: string,
  items: array,
  mobileRowsCount: string,
}

export default SectionCards
