import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "components/UI/Button"
import { ArrowDownAltIcon } from "assets/icons"

import {
  StoreDownloadBadgesAppStore,
  StoreDownloadBadgesGooglePlay,
} from "components/UI/StoreDownloadBadges"
import Constants from "lib/constants"

const BannerDownload = ({ className }) => {
  return (
    <div className={classNames(className, "flex")}>
      <Button
        className="md:hidden"
        label="Download NobodySurf"
        iconAfter={<ArrowDownAltIcon />}
        variant="contained"
        theme="light"
        target="_blank"
        rel="noopener"
        href={Constants.Links.BadgeTopPage}
      />
      <div className="hidden md:flex">
        <StoreDownloadBadgesAppStore href={Constants.Links.BadgeTopPage} />
        <StoreDownloadBadgesGooglePlay
          className="ml-4"
          href={Constants.Links.BadgeTopPage}
        />
      </div>
    </div>
  )
}

BannerDownload.propTypes = {
  className: PropTypes.string,
  sendLinkForm: PropTypes.bool,
}

export default BannerDownload
